/*@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Thin.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}
*/
@font-face {
  font-family: 'Slate Pro';
  src: url(./assets/fonts/SlatePro-Book.otf) format('opentype');
  font-weight: 350;
}

@font-face {
  font-family: 'Slate Pro';
  src: url(./assets/fonts/SlatePro-BookItalic.otf) format('opentype');
  font-weight: 350;
  font-style: italic;
}
/*

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}
*/
@font-face {
  font-family: 'Slate Pro';
  src: url(./assets/fonts/SlatePro-Bold.otf) format('opentype');
  font-weight: 700;
}
/*
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}
*/

.grey-text {
  color: #6e7171;
}

.blue-text {
  color: #06a6ba;
}

.purple-text {
  color: #624b78;
}

.header {
  padding: 5px 5%;
  font-weight: 700;
  font-size: 24px;
}

.header-img {
  width: 100%;
}

.content {
  padding: 0 10% 5px;
}

.upper-container {
  background-color: #EBEBEC;
}

.form-item {
  margin-bottom: 10px;
}

.form-label {
  margin-bottom: 4px;
  font-family: 'Slate Pro';
  font-weight: 700;
  font-size: 18pt;
  color: #624b78;
}

.green-text {
  color: #71b790;
}

.title {
  font-weight: 700;
  font-size: 42pt;
  font-family: 'Slate Pro';
  margin-bottom: 30px;
}

.subscript {
  font-size: 22pt;
}

.subtitle {
  font-weight: 700;
  font-size: 38pt;
}

.register {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0;
}

.register-btn-container {
  display: flex;
  justify-content: center;
  margin: 20px 0 30px 0;
}

.register-btn {
  background-image: url("https://nurtec-registration.s3.amazonaws.com/register_btn.png");
  height: 67px;
  width: 360px;
  font-family: 'Slate Pro' !important;
  font-weight: 700 !important;
  font-size: 28pt !important;
  color: #ffffff !important;
}

.isi-header {
  color: #71b790;
  font-weight: 700;
  font-family: 'Slate Pro';
  font-size: 18pt;
}

.isi-subheader {
  color: #707372;
  font-weight: 700;
}

.isi-section {
  margin-top: 8px;
  font-weight: 350;
  font-family: 'Slate Pro';
  font-size: 18pt;
  color: #707372;
}

.isi-pi {
  font-weight: 700;
}

.isi-pi a:link, .isi-pi a:visited {
  color: #707372;
}

.footer {
  margin-top: 14px;
  font-size: 18pt;
  display: flex;
  align-items: center;
  font-family: 'Slate Pro';
  font-weight: 350;
  color: #4a4a4a;
}

.program-container {
  margin-bottom: 30px;
}

.footer-img-container {
  width: 260px;
  margin-right: 20px;
  margin-bottom: 20px;
  align-self: flex-end;
  text-align: end;
}

@media (max-width: 768px) {
  .footer-img-container {
    width: 160px;
    align-self: flex-end;
  }
}

.footer-img {
  width: 15%;
  min-width: 150px;
}

body {
  padding: 0;
  margin: 0;
}

.indication {
  align-self: flex-end;
}

.indication-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.indication-disclaimer {
  font-size: 18pt;
  font-weight: 350;
  color: #707372;
  font-family: 'Slate Pro';
}

.cta-register {
  background-image: url("https://nurtec-registration.s3.amazonaws.com/CTA.png");
  height: 67px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 28pt;
  font-family: 'Slate Pro';
  color: #624b78;
}

.cta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.program-date {
  font-size: 36pt;
  font-weight: 700;
  font-family: 'Slate Pro';
  margin-bottom: 30px;
}

.program-desc {
  max-width: 1300px;
  font-size: 38pt;
  font-weight: 700;
  font-family: 'Slate Pro';
}

.required-fields {
  font-size: 18pt;
  font-weight: 350;
  font-family: 'Slate Pro';
  color: #4a4a4a;
  margin: 30px 0;
}

.bottom-footer {
  background-color: #FFFFFF;
  margin-top: 30px;
  color: #4a4a4a;
}

.isi-section.no-margin {
  margin-top: 0;
}

.bottom-footer .isi-section {
  color: #4a4a4a;
}

input {
  background-color: #FFFFFF !important;
}

.pink-bracket {
  color: #ff00ff;
}

.footer-date {
  margin-top: 10px;
}

.event-time-btn-container {
  display: flex;
  gap: 10px;
}

.event-time-btn {
  width: 255px;
  height: 47px;
  background-image: url("https://nurtec-registration.s3.amazonaws.com/event_time_btn.png");
  background-color: unset !important;
  font-size: 19.64pt !important;
  font-weight: 700 !important;
  font-family: 'Slate Pro' !important;
  color: #624b78 !important;
  box-shadow: 10px 10px 10px #c1c1c1 !important;
}

.event-time-btn.pressed {
  -webkit-box-shadow: inset 2px 2px 10px #c1c1c1 !important;
  -moz-box-shadow: inset 2px 2px 10px #c1c1c1 !important;
       box-shadow: inset 2px 2px 10px #c1c1c1 !important;
  outline: none !important;  
}

.event-time-btn span {
  font-size: 15pt;
}

.event-time-err {
  margin-top: 5px;
  color: red;
  display: none;
}

.event-time-err.show {
  display: block;
}

@media only screen and (max-width: 450px) {
  .event-time-btn {
    font-size: 15pt !important;
  }

  .event-time-btn span {
    font-size: 10pt !important;
  }

  .indication-container {
    flex-direction: column-reverse;
    gap: 10px;
  }

  .indication-disclaimer, .isi-header, .isi-section {
    font-size: 16pt;
  }

  .title {
    font-size: 25pt;
  }

  .program-date, .program-desc {
    font-size: 22pt;
  }

  .footer {
    font-size: 16pt;
  }
}

@media only screen and (max-width: 350px) {
  .event-time-btn {
    font-size: 12pt !important;
  }

  .event-time-btn span {
    font-size: 8pt !important;
  }
}